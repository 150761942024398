import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import Layout from "../components/layout";
import SEO from "../components/seo";

//components
import ImageBackground from "../components/imagebackground";
import settings from "../../settings.js";
import DeliveryInstructionsHeader from "../components/deliveryInstructionsHeader";

//images
const deliveryBanner = `${settings.IMAGES_BASE_URL}/images/delivery-instructions/banner.jpg`;
const mobDeliveryBanner = `${settings.IMAGES_BASE_URL}/images/delivery/bmw-delivery-mobile.jpg`;


class DeliveryInstructions extends React.Component {
  render() {
    return (
      <Layout>
        <SEO
          title="BMW Performance Driving School | Hotel Partners"
          description="Both locations of BMW's Performance Driving School - Spartanburg, South Carolina and Thermal/Palm Springs, California - offer the BMW experience of a lifetime!"
          keywords="bmw driving school greenville sc, bmw driving school near me, bmw driving school thermal, bmw driving school in south carolina, bmw driving school palm springs, bmw driving school experience"
        />
          <ImageBackground
            imageDesktop={deliveryBanner}
            imageMobile={mobDeliveryBanner}
            imageAlt="Delivery banner"
            hideMobileHeader={true}
          />
          <section className="delivery_instructions">
            <DeliveryInstructionsHeader/>
            <div className="delivery_instructions__content">
              <div className="container">
                <div className="header">
                  <h2>KNOW BEFORE YOU GO</h2>
                </div>
                <div className="row">
                  <div className="sub-content">
                    <h4>MAKE YOUR VIP LIST</h4>
                    <p>
                      • Your delivery day will start at 8 AM and conclude around 3 PM.<br/>
                      • Your BMW Experience is designed for you and one guest 12 years of age and older. Additional guests are not able to be accommodated. Unfortunately, there are no exceptions.<br/>
                      • All driving participants must present a valid driver’s license (participants under 18 years of age must be accompanied by a legal guardian).
                    </p>
                  </div>
                  <div className="sub-content">
                    <h4>DRIVING SCHOOL WAIVER</h4>
                    <p>
                      Prior to arriving, you and your guest can pre-register separately using the link below. You will register as the “Primary Participant” and your guest will register as the “Guest” for the participant type.
                    </p>
                    <br/>
                    <br/>
                    <p>
                      You will need the following information, which can be found in your confirmation email: <br/>
                      • Experience date <br/>
                      • Vehicle production number
                    </p>
                    <br/>
                    <a href="https://s3.amazonaws.com/themes.limelightplatform.com/client/bmw-na/files/Performance+Driving+School/12156-events-waivers.pdf" target="_blank" rel="noopener noreferrer">
                      <button class="btn btn-bordered">Sign your waiver now</button>
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="sub-content">
                    <h4>TAX INFORMATION</h4>
                    <p>
                      The following information applies only if you reside in the following states:<br/>
                      Arkansas, Maryland, Oklahoma, District of Columbia , Mississippi, South Dakota, Indiana, North Carolina, Virginia, Kentucky, New Mexico and West Virginia<br/><br/>
                      The state of South Carolina imposes a use tax of $500 upon the first use of a new vehicle in South Carolina, purchased from a state listed above. Since you will be taking delivery of your new vehicle in South Carolina, this tax may apply.<br/><br/>
                      This first-time use tax will not be collected by your retail center or the BMW Performance Center. Should the state of South Carolina decide to collect this tax from out-of-state consumers, it would be billed by the state of South Carolina and payable directly to the same.<br/><br/>
                      The BMW Performance Center is not aware of any instance where the state of South Carolina has sought this first-time use tax against any of our delivery customers. In the event taxes are assessed and were paid in your home state, recovery may be available in full or part. Note that such recovery may not be available from states adjoining South Carolina.
                    </p>
                  </div>
                  <div className="sub-content">
                    <h4>THINGS TO BRING</h4>
                    <p>
                      • Valid driver’s license • Your license plate or temporary tag if provided<br/>
                      • South Carolina requires all vehicles to be registered and insured. Therefore, we highly recommend bringing a copy of your bill-of-sale, as well as proof of insurance to keep in your vehicle.<br/>
                      • Camera (there’s a lot you’ll want to capture!)<br/>
                      • Add #BMWDelivery to share your new arrival. 
                    </p>
                    <br/>
                    <br/>
                    <h4>TRAVEL INFORMATION </h4>
                    <p>
                      BMW Performance Center<br/>
                      1155 Highway 101 South<br/>
                      Greer, SC 29651<br/>
                      Phone: 888-345-4269<br/>
                      Email: PerformanceCenterDelivery@bmwmc.com
                    </p>
                    <br/>
                    <a href="https://www.google.com/maps/place/1155+SC-101,+Greer,+SC+29651/@34.9045674,-82.1818842,17z/data=!3m1!4b1!4m6!3m5!1s0x885781f9de178019:0xe5e780c9038ea2ec!8m2!3d34.904563!4d-82.1793039!16s%2Fg%2F11kj040tfv?entry=ttu" target="_blank" rel="noopener noreferrer">
                      <button class="btn btn-bordered">See Map and Directions</button>
                    </a><br/>
                    <a href="/deliveryfaq" target="_blank" rel="noopener noreferrer">
                      <button class="btn btn-bordered">Explore our Delivery FAQ</button>
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="sub-content-2">
                    <h4>DRESS CODE</h4>
                    <p>
                      Dress code is casual and comfortable. Completely enclosed shoes are required. We suggest tennis shoes.<br/><br/>
                      • No raised heel.<br/> 
                      • No open toes, heels, sides, backs or spaces.<br/> 
                      • The shoe material must protect the entire foot.<br/> 
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="sub-content-2">
                    <h4>DELIVERY DAY ITINERARY</h4>
                    <p>
                      Please arrive at 7:45 AM for check-in. Unfortunately, you will miss out on your driving activities if you arrive late.
                    </p>
                    <br/>
                    <br/>
                    <h4>The day is split into many segments:</h4>
                    <div className="inner-content">
                      <h4>Driving Experience</h4>
                      <p>During this segment, you’ll spend time behind the wheel of a BMW that is similar to the one you purchased. We’ll showcase the advantages and technologies of your BMW on our track. You’ll experience the handling capabilities and discover why it’s called the Ultimate Driving Machine®. You will also learn why BMWs are known for having the best braking systems as we push the ABS brakes to their limit. We’ll then showcase the advantages of DSC (Dynamic Stability Control) on our skid pad.</p>
                    </div>
                    <div className="inner-content">
                      <h4>xDrive Course</h4>
                      <p>Have you ever wondered what it feels like to be on two wheels? You’ll get the chance to experience the water crossing, spiral mountain, hill decent control and more in a BMW SAV.</p>
                    </div>
                    <div className="inner-content">
                      <h4>BMW Factory Tour</h4>
                      <p>Tour BMW Manufacturing (when public tours are available), where BMW X vehicles are assembled. Please note that tours are sometimes closed due to new models releases.</p>
                    </div>
                    <div className="inner-content">
                      <h4>Vehicle Delivery</h4>
                      <p>
                        It wouldn’t be the Ultimate Delivery Experience if we didn’t explore your new BMW together. During this segment, a BMW Product Specialist will demonstrate and explain many features of your new BMW, making sure you feel confident with the controls when you leave.<br/><br/>
                        Lunch will be served from 12 – 12:45 PM at the Performance Center M Café. You can expect to be on the road in your new BMW no later than 3 PM.
                      </p>
                    </div>
                    <div className="inner-content">
                      <h4>Shipping Your BMW Home</h4>
                      <p>If you choose not to drive your new BMW home, you will need to make arrangements with a shipping carrier to pick up your vehicle the same day you have it delivered. You must be available to hand the keys directly to your carrier.</p>
                    </div>
                    <div className="inner-content">
                      <h4>Zentrum Museum</h4>
                      <p>At the conclusion of your delivery day, you are welcome to visit the Zentrum Museum, located at the BMW factory. It houses a beautiful collection of past BMWs. You’ll have time to walk around and see some of your favorite classics, as well as visit their gift shop.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          </Layout>
    )
  }
}

export default DeliveryInstructions;